<template>
  <va-card v-if="showGrid" title="Customer Organization">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="showGrid = false">Add</va-button>
      <va-button color="orange" v-if="isEnable" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>
    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="edit" slot-scope="props">
        <va-button flat small 
        color="gray" icon="fa fa-pencil" 
        @click="editCustomer(props.rowData)" 
        class="ma-0"/>
      </template>
    </va-data-table>
  </va-card>
  <va-card v-else :title="isCreate?'create customer org':'update customer org'">
    <template slot="actions">
      <va-button icon="fa fa-list"
        @click="resetValue()" color="gray">List</va-button>
    </template>
    <form @submit.prevent="isCreate?addCustomer():updateCustomer()">
      <va-input
        label="Customer Organization Name *"
        placeholder="Enter Customer Organization Name"
        v-model="customer_org.customer_org_name"
        required="true"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
      />      
      <va-select
        v-if="role != 'RESELLER'"
        label="Organization Name *"
        placeholder="Select Organization Name"
        v-model="customer_org.org_id"
        :options="orgOtns"
        textBy="org_name"
        required="true"
      />      
      <va-input 
        v-else
        label="Organization Name *"
        placeholder="Select Organization Name"
        v-model="customer_org.org_id.org_name"
        textBy="org_name"
        :disabled="true"
      />
      <va-input
        label="GST *"
        placeholder="Enter GST"
        v-model="customer_org.gstin"
        required="true" 
      />
      <va-input
        label="Contact Name *"
        placeholder="Enter Contact Name"
        v-model="customer_org.customer_name"
        required="true"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
      />  
        <va-input
        label="Email *"
        type="email"
        pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
        placeholder="Enter Email"
        v-model="customer_org.email"
        required="true"
      />
      <va-input
        label="Account Email *"
        type="email"
        pattern="^[a-z0-9.]+@[a-z0-9.]+\.[a-z]{1,4}$"
        placeholder="Enter Account Email"
        v-model="customer_org.accounts_email"
        required="true"
      />
      <va-input
        label="Account ID *"
        type="number"
        placeholder="Enter Account ID"
        v-model="customer_org.account_id"
        required="true"
      />
      <va-input
        label="Mobile Number *"
        pattern="^[6-9]{1}[0-9]{9}$"
        maxlength="10"
        placeholder="Enter Mobile Number"
        v-model="customer_org.mobile_number"
        required="true"
      />
      <va-input
        label="Address *"
        placeholder="Select Address"
        v-model="customer_org.address"
        required="true"
      />    
      <va-select
        label="Country *"
        textBy="name"
        searchable
        placeholder="Select Country"
        v-model="customer_org.country"
        :options="countries"
        noClear
      />   
      <va-select
        label="State *"
        textBy="name"
        searchable
        placeholder="Select State"
        v-if="states.length && customer_org.country"
        v-model="customer_org.state"
        :options="states"
        noClear
      />   
      <va-select
        label="City *"
        searchable
        textBy="name"
        placeholder="Select City"
        v-if="cities.length && customer_org.state"
        v-model="customer_org.city"
        :options="cities"
        noClear
      />
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" flat @click="resetValue()">Cancel</va-button>
        <va-button type="submit" class="my-0">{{isCreate?'Create':'Update'}}</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)

export default { 
  created(){
    this.getAllCustomer()
    this.getOrgs()
    this.getAllCountries()
  },
  data () {
    return {
      term:null,
      isEnable:false,
      perPage:'10',
      role:"",
      perPageOptions:['4', '6', '10', '20'],
      showGrid:true,
      isCreate:true,
      statusOtns:["NEW","DRAFT","ACTIVE","SUSPENDED"],
      orgOtns:[],
      countries : [],
      states : [],
      cities : [],
      customer_orgs: [],
      isfirst : {
        state : false,
        city : false
      },
      customer_org:{
        customer_org_name:"",
        org_id:"",
        account_id:"",
        gstin:"",
        address:"",
        country:"",
        state:"",
        city:"",
        customer_name:"",
        email:"",
        accounts_email:"",
        mobile_number:""
      },
    }
  },
  watch:{
    'customer_org.country'(val){
      this.customer_org.state = this.isfirst.state ? this.customer_org.state : ""
      this.isfirst.state = false
      val && this.getStates(val.country_id)
      if (!val)
        this.states = []
    },
    'customer_org.state'(val){
      this.customer_org.city = this.isfirst.city ? this.customer_org.city : ""
      this.isfirst.city = false
      val && this.getCities(this.customer_org.country.country_id,val.state_id)
      if (!val)
        this.cities = []
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'sno',
          width: "8%"
        },
        {
          name: 'customer_org_name',
          title: 'Customer Org Name',
          width: "13%"
        },
        {
          name: 'account_id',
          title: 'Account ID',
          width: "13%"
        },
        {
          name: 'address',
          title: 'Address',
          width: "15%"
        },
        {
          name: 'country.name',
          title: 'Country',
          width: "16%"
        },
        {
          name: 'customer_name',
          title: 'Contact Name',
          width: "16%"
        },
        {
          name: 'email',
          title: 'Email',
          width: "16%"
        },
        {
          name: 'status',
          title: 'Status',
          width: "20%"
        },        
        {
          name: '__slot:edit',
          dataClass: 'text-right',
          width: "20%"
        }
      ]
    },
    filteredData () {
      let filteredPlatform = search(this.term, this.customer_orgs)
      return filteredPlatform
    }
  },
  methods:{
    getCities(val1,val2){
      this.$http.get(`${config.menu.host}customer_org/country/${val1}/state/${val2}`)
      .then(res => {
        res.body.map((item) => {
          item.id=item.city_id
        })
        this.cities = res.body
      })
    },
    getStates(val){
      this.$http.get(`${config.menu.host}customer_org/country/${val}`)
      .then(res => {
        res.body.map((item) => {
          item.id=item.state_id
        })
        this.states = res.body
      })
    },
    getAddressDet(){
      for (let i = 0; i < this.customer_orgs.length; i++) {
        const customer = this.customer_orgs[i];
        this.$http.get(`${config.menu.host}customer_org/country/${customer.country}/state/${customer.state ? customer.state : 0}/city/${customer.city ? customer.city : 0}`)
        .then(res => {
          customer.country = res.data.country
          customer.state = res.data.state
          customer.city = res.data.city
        })
      }
    },
    getAllCountries(){
      this.$http.get(config.menu.host+'customer_org/countries')
      .then(res => {
        res.body.map((item) => {
          item.id=item.country_id
        })
        this.countries = res.body
      })
    },
    getOrgs(){
      this.$http.get(config.menu.host+'org')
      .then(res => {
        this.getUserInfo()
        .then(result => {
          this.orgOtns = res.data
          if (result.data.role == 'SYS_ADMIN'){
            this.orgOtns = this.orgOtns.filter(org => org.org_type != 'SYS_ORG')
            this.orgOtns.map((org,i) => org.id = ++i) 
          }
          else {
            this.role = result.data.role
            this.customer_org.org_id = this.orgOtns.find(org => org.org_id == result.data.org_id)
          }
        })
      })
    },
    getUserInfo() {
      let user_id = Vue.$cookies.get('user-id')
      return new Promise((resolve) => {
        this.$http.get(config.menu.host + 'user/' + user_id)
        .then(res => {
          resolve(res)
        })
      })
    },
    exportData(){
      var rowKeys = {
        account_id: 'Account ID',
        customer_name: 'Contact Name',
        accounts_email: 'Accounts Email',
        state: 'State'
      }
      return exportToFile(this.customer_orgs, rowKeys, 'Customer Orgs', 'CSV')
    },
    editCustomer(customer_org){
      Object.entries(this.customer_org).map(key => {
        if (key[0] == 'org_id'){
          customer_org[key[0]] = this.orgOtns.find(org => org.org_id == customer_org[key[0]])
        }
        if(customer_org[key[0]]) {
          this.customer_org[key[0]] = customer_org[key[0]]
        }
      })
      this.customer_org_id = customer_org.customer_org_id
      this.showGrid = false
      this.isCreate = false
      this.isfirst.state = true
      this.isfirst.city = true
    },
    resetValue(){
      this.showGrid = true
      this.isCreate = true
      Object.entries(this.customer_org).forEach(key => this.customer_org[key[0]] = '')
    },
    getAllCustomer(){
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host+'customer_org')
      .then(async res => {
        loader.hide()
        this.customer_orgs = res.data
        this.isEnable = (this.customer_orgs.length > 0) ? true : false
        this.customer_orgs.map((customer_org,i) => customer_org.sno = i+1)
        this.getAddressDet()
      })
      .catch(error => {
        loader.hide()
        Vue.notify({text: error.body, type: 'error'})
      })
    },
    addCustomer(){
      for (const key in this.customer_org) {
        if (key == 'state' && !this.states.length) continue
        if (key == 'city' && !this.cities.length) continue
        if (this.customer_org[key] == "") {
          Vue.notify({ text: "Please fill the form correctly",type: 'error'})
          return
        }
      }
      let payload = { ...this.customer_org }

      payload.country = payload.country ? payload.country.country_id : ""
      payload.state   = payload.state ? payload.state.state_id : ""
      payload.city    = payload.city ? payload.city.city_id : ""
      payload.org_id  = payload.org_id.org_id

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host+'customer_org',payload)
      .then(res => {
        loader.hide()
        Vue.notify({ text: res.body,type: 'success'})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({text: err.body,type: 'error'})
      })
      .then(() => {
        this.resetValue()
        this.getAllCustomer()
      })
    },
    updateCustomer(){
      let validation = true
      for (const key in this.customer_org) {
        if (key == 'state' && !this.states.length) continue
        if (key == 'city' && !this.cities.length) continue
        if (!this.customer_org[key]) {
          validation = false
        }
      }
      if(!validation) {
        Vue.notify({ text: "Please fill the form correctly",type: 'error'})
        return
      }
      let payload = { ...this.customer_org }

      payload.country = payload.country ? payload.country.country_id : ""
      payload.state   = payload.state ? payload.state.state_id : ""
      payload.city    = payload.city ? payload.city.city_id : ""
      payload.org_id  = payload.org_id.org_id

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host+`customer_org/${this.customer_org_id}`,payload)
      .then(res => {
        loader.hide()
        Vue.notify({text: res.body,type: 'success'})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({text: err.body,type: 'error'})
      })
      .then(() => {
        this.resetValue()
        this.getAllCustomer()
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  }
}
</script>

<style scoped>
.badge {
  padding: 8px 0.5rem;
}

</style>